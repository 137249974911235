import axios from "axios";
import { API } from "../../../utils/axios/index";

export const resolveChatQuery = async (body, flowType = "navigation") => {
  //console.log("changes resolveChatQuery -->", body, flowType); //= "navigation"
  try {
    const response = await API.put(`chatbot/${flowType}`, body);
    const { data } = response;
    return data;
  } catch (error) {
    console.error("resolveChatQuery error", error);
    return null;
  }
};

export const fetchRelatedQuestions = async (flowType, screen_name) => {
  try {
    const response = await API.get(
      `chatbot/${flowType}/question/${screen_name}`
    );
    const { data } = response;
    //this code adds flowtype to questions as it does not get flowtype from api
    let res = data.data.questions[0].map((item) => {
      return {
        ...item,
        flow_type: flowType,
      };
    });
    data.data.questions[0] = res;
    return data;
  } catch (error) {
    console.error("fetchRelatedQuestions error", error);
    return null;
  }
};

export const getSmartBotVisibilityData = async () => {
  try {
    const response = await API.get(`/chatbot/show_chatbot_icon`);
    const { data } = response;
    return data.result;
  } catch (error) {
    console.error("getSmartBotVisibilityData error", error);
  }
};

export const refreshAndUpdateUserManualApi = async (body) => {
  try {
    API.put(`chatbot/manualdoc`, body).then((response) => {
      return response;
    });
    // return axiosInstance({
    //   url: `core/chatbot/manualdoc`,
    //   method: "PUT",
    //   data: body,
    // });
  } catch (error) {
    console.error("refreshAndUpdateUserManualApi error", error);
    return null;
  }
};

export const fetchScreensForModule = async (flowType) => {
  try {
    const response = await API.get(`chatbot/${flowType}/screen`);
    const { data } = response;
    return data;
  } catch (error) {
    console.error("fetchScreensForModule error", error);
    return null;
  }
};

// flowtype - Insights || Navigationts
