import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  smartBotActive: false,
};

const smartBotSlice = createSlice({
  name: "smartbot",
  initialState,
  reducers: {
    setSmartBotActive(state, action) {
      state.smartBotActive = !state.smartBotActive;
    },
  },
});

export const { setSmartBotActive } = smartBotSlice.actions;

// Export the slice reducer as the default export
export default smartBotSlice.reducer;

// export default function (state = initialState, action) {
//   switch (action.type) {
//     case SET_SMART_BOT_ACTIVE:
//       return {
//         ...state,
//         smartBotActive: action.payload,
//       };

//     default:
//       return state;
//   }
// }
