import styled from "styled-components";
import RefreshIcon from "@mui/icons-material/Refresh";

export const StyledRefreshIcon = styled(RefreshIcon)`
  & path {
    fill: ${(props) => (props.loading ? "#758490" : "#0055AF")};
  }
`;

// props.loading
//   ? "#758490"
//   : "#0055AF"};
// Change fill color of the path based on loading prop
