export const getUserName = () => {
  try {
    const userID = localStorage.getItem("name");
    let userName = userID ? userID.split("@")[0] : "User";
    userName = userName
      .split(".")
      .map((item) => item.charAt(0).toUpperCase() + item.slice(1))
      .join(" ");
    return userName;
  } catch (error) {
    console.error("getUserName error", error);
  }
};

/**
 * pxToRem function will convert the px value
 * to rem value and return it
 */
export const pxToRem = (pxValue) => {
  try {
    return pxValue / 16 + "rem";
  } catch (error) {
    console.error("pxToRem error:", error);
  }
};
