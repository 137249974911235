import React, { useMemo } from "react";
import RedirectLink from "./redirect-link";
import HighlightedRenderer from "./highlighted-renderer";
import RefreshIcon from "@mui/icons-material/Refresh";
import moment from "moment";
import { Typography } from "@mui/material";
import { Button } from "impact-ui";

const MessageTemplate = (props) => {
  const {
    templateData,
    endCurrentSession,
    clearChatSession,
    screenName,
    currentAppLink,
  } = { ...props };

  const dateFormat = "DD-MM-YYYY HH:mm:ss";

  const renderActionableCTA = () => {
    return (
      <>
        {screenName && (
          <div className="layout-align-start">
            {/* <RedirectLink to={currentAppLink} screenName={screenName} /> */}
          </div>
        )}
        <div className="flex-row gap">
          <Button variant="url" onClick={endCurrentSession}>
            End Chat
          </Button>
          <Button variant="url" onClick={clearChatSession}>
            Clear Chat
          </Button>
        </div>
      </>
    );
  };

  const renderDateChip = (dateString) => {
    return (
      <Typography variant="body1" component="span" className="data-chip">
        {dateString}
      </Typography>
    );
  };

  const botView = (botData) => {
    let time = moment(botData.timeStamp, dateFormat).format("HH:mm A");
    return (
      <div className="bot-view-block flex-row flex-column">
        <div className="flex-row layout-align-between-center">
          <Typography variant="text" className="bot-view-header">
            {botData.userName}
          </Typography>
          <Typography variant="text" className="bot-view-header">
            {time}
          </Typography>
        </div>
        {botData?.noShowHeaderTitle ? (
          <></>
        ) : (
          Boolean(botData.headerTitle?.length) && (
            <div className="layout-align-start align-items-start">
              <Typography>{botData.headerTitle}</Typography>
            </div>
          )
        )}
        <div className="layout-align-start align-items-start">
          {parseBody(botData)}
        </div>
      </div>
    );
  };

  const parseBody = (botData) => {
    switch (botData.bodyType) {
      case "text":
        return Boolean(botData.bodyText.includes("\n")) ? (
          <div className="align-items-start">
            {botData.bodyText.split("\n").map((text) => (
              <>
                <Typography className="bot-body-text-styling">
                  {text}
                </Typography>
                <br />
              </>
            ))}
          </div>
        ) : (
          <Typography className="bot-body-text-styling">
            {botData.bodyText}
          </Typography>
        );
      case "chips":
        return (
          <div className="flex-row flex-wrap gap vertical-align-center">
            {botData.bodyText.map((data) => {
              const callBack = data.interactable
                ? Object.entries(props).filter(
                    (entry) => entry[0] === data.actionName
                  )?.[0]?.[1]
                : null;

              return (
                <Typography
                  component="span"
                  variant="body1"
                  className={`gpt-chips ${
                    data.interactable ? "cursor-pointer" : ""
                  }`}
                  onClick={() => {
                    callBack && callBack(data);
                  }}
                >
                  {data.displayText}
                </Typography>
              );
            })}
          </div>
        );
      case "questions":
        return (
          <div className="flex-row flex-column gpt-question-container">
            {botData.bodyText.map((data) => {
              const callBack = data.interactable
                ? Object.entries(props).filter(
                    (entry) => entry[0] === data.actionName
                  )?.[0]?.[1]
                : null;
              return (
                <div className="layout-align-start gpt-question-block">
                  <Typography
                    component="span"
                    variant="button"
                    className="max-width-fit-content"
                  >
                    Ques{" " + data.question_index}
                  </Typography>
                  <Typography
                    component="span"
                    variant="body1"
                    className={`gpt-questions ${
                      data.interactable ? "cursor-pointer" : ""
                    }`}
                    onClick={() => {
                      callBack && callBack(data);
                    }}
                  >
                    <HighlightedRenderer sentence={data.displayText} />
                  </Typography>
                </div>
              );
            })}
          </div>
        );
    }
  };

  const userView = (userData) => {
    let time = moment(userData.timeStamp, dateFormat).format("HH:mm A");
    return (
      <div className="user-view-block flex-row layout-align-space-between gap">
        {Boolean(userData.bodyText.includes("\n")) ? (
          <div>
            {userData.bodyText.split("\n").map((text) => (
              <Typography className="user-body-text-styling">{text}</Typography>
            ))}
          </div>
        ) : (
          <Typography className="user-body-text-styling">
            {userData.bodyText}
          </Typography>
        )}
        <Typography variant="text" className="bot-view-header">
          {time}
        </Typography>
      </div>
    );
  };

  const getLoader = (botData) => {
    let time = moment(botData.timeStamp, dateFormat).format("HH:mm A");
    return (
      <div className="bot-view-block flex-row flex-column">
        <div className="flex-row layout-align-between-center">
          <Typography variant="text" className="bot-view-header">
            {botData.userName}
          </Typography>
          <Typography variant="text" className="bot-view-header">
            {time}
          </Typography>
        </div>
        <div className="position-relative">
          <div className="loader"></div>
        </div>
      </div>
    );
  };

  const renderChats = useMemo(() => {
    if (!templateData?.length) {
      return <></>;
    }
    let dayData;
    return templateData?.map((data, index) => {
      let currentDayData;
      if (data.timeStamp) {
        let date = moment(data.timeStamp, dateFormat).date();
        let month = moment(data.timeStamp, dateFormat).format("MMMM");
        let year = moment(data.timeStamp, dateFormat).format("YYYY");
        currentDayData = `${date} ${month} ${year}`;
      }

      let dateChip;
      if (currentDayData != dayData) {
        dayData = currentDayData;
        dateChip = renderDateChip(currentDayData);
      }

      let chatCTA;
      if (
        ["bot", "loader"].includes(data.userType) &&
        templateData.length === index + 1
      ) {
        chatCTA = renderActionableCTA();
      }
      switch (data.userType) {
        case "bot":
          return (
            <>
              {dateChip}
              {botView(data)}
              {chatCTA}
            </>
          );
        case "user":
          return (
            <>
              {dateChip}
              {userView(data)}
            </>
          );
        case "loader":
          return (
            <>
              {getLoader(data)}
              {chatCTA}
            </>
          );
        default:
          return <></>;
      }
    });
  }, [templateData]);

  return <>{renderChats}</>;
};

export default MessageTemplate;
