import React, { useEffect, useState, useRef, useMemo } from "react";
import SendIcon from "../../../assets/sendIcon.svg";
import CloseIcon from "@mui/icons-material/Close";
import RemoveIcon from "@mui/icons-material/Remove";
import { Button, Input } from "impact-ui";
import { navigationOptions, insightsOptions } from "./smartBotConstants";
import moment from "moment";
import { Typography } from "@mui/material";
import { cloneDeep, isNull } from "lodash";
import {
  resolveChatQuery,
  fetchScreensForModule,
  fetchRelatedQuestions,
  refreshAndUpdateUserManualApi,
} from "./smartBotservices";
// import { getUserName } from "./utils";
import { useLocation, useNavigate } from "react-router";
// import { addSnack } from "core/actions/snackbarActions";
import { useDispatch } from "react-redux";
import { StyledRefreshIcon } from "./styled";
import "./smartBot.scss";
import { connect } from "react-redux";
import Snackbar from "../../ui/snackbar/Snackbar";
import MessageTemplate from "./messageTemplete";
import MinimizedContainer from "./minimizedContainer";
import "./smartBot.scss";

const SmartBot = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [minimizedMode, setMinimizedMode] = useState(false);
  const [userInput, setUserInput] = useState("");
  const [flowType, setFlowType] = useState(null);
  const [screenName, setScreenName] = useState("");
  const [questionIndex, setQuestionIndex] = useState(0);
  const [currentAppLink, setCurrentAppLink] = useState("/home");
  const [loader, setLoader] = useState(false);
  const [refreshLoader, setRefreshLoader] = useState(false);
  const chatDataRef = useRef();
  const chatBodyRef = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  const dateFormat = "DD-MM-YYYY HH:mm:ss";
  const dispatch = useDispatch();
  const [displayMessage, setDisplayMessage] = useState(false);
  const [snackMessage, setSnackMessage] = useState({
    message: "",
    variant: "info",
  });

  const userName = localStorage.getItem("user")
    ? localStorage.getItem("user").split(" ")[0]
    : "User";

  useEffect(() => {
    if (props.invokeBot) {
      setMinimizedMode(!props.invokeBot);
      setShowModal(props.invokeBot);
    }
  }, [props.invokeBot]);

  useEffect(() => {
    let screenNameStored = localStorage.getItem("smartBotScreenName");
    let currentAppLinkStored = localStorage.getItem("smartBotCurrentAppLink");
    if (screenNameStored) {
      setScreenName(screenNameStored);
    }
    if (currentAppLinkStored) {
      setCurrentAppLink(currentAppLinkStored);
    }
  }, [location]);

  useEffect(() => {
    if (showModal) {
      if (!chatDataRef?.current?.length) {
        const data = localStorage.getItem("chatData");
        let chatData = JSON.parse(data);
        if (chatData?.length > 0) {
          const savedChatSession = chatData;
          parseSavedFlow(savedChatSession);
        } else {
          initiateNewChat();
        }
      }
    }
  }, [showModal]);

  useEffect(() => {
    if (chatDataRef.current) {
      saveCurrentChanges();
      const scrollToPosition = Number(
        chatBodyRef.current?.lastChild?.offsetTop
      );
      chatBodyRef.current?.scrollTo({
        top: scrollToPosition,
        behavior: "smooth",
      });
    } else {
      initiateNewChat();
    }
  }, [chatDataRef.current]);

  const parseSavedFlow = (savedChatSession) => {
    if (
      !savedChatSession.length ||
      savedChatSession[savedChatSession.length - 1].userType === "bot"
    ) {
      chatDataRef.current = savedChatSession;
    } else {
      const userType = savedChatSession[savedChatSession.length - 1].userType;
      const lastIndex = userType === "loader" ? 2 : 1;
      const savedUserInput =
        savedChatSession[savedChatSession.length - lastIndex];
      chatDataRef.current = savedChatSession.slice(
        0,
        savedChatSession.length - lastIndex
      );
      if (savedUserInput?.hasDirectActionType) {
        fetchUserResultsFromQuery(savedUserInput, true);
      } else {
        console.log("kalli 3", savedUserInput.flow_type);
        setFlowType(savedUserInput.flow_type || "testing");
        setScreenName(savedUserInput.screen_name);
        setUserInput(savedUserInput.screen_name);
      }
    }
  };

  const saveCurrentChanges = () => {
    const charData = JSON.stringify(chatDataRef.current);
    localStorage.setItem("chatData", charData);
  };

  const endCurrentSession = () => {
    clearChatSession();
    setShowModal(false);
    setMinimizedMode(false);
    setScreenName("");
    props.closeBot(false);
  };

  const clearChatSession = () => {
    setFlowType(null);
    setUserInput("");
    setScreenName("");
    initiateNewChat();
  };

  const initiateNewChat = () => {
    const initialGreet = [
      {
        timeStamp: getCurrentDateTimeString(),
        userType: "bot",
        userName: "Smart Sage - The smart bot assist",
        headerTitle: `Hi ${userName}!`, //${getUserName()}
        bodyType: "text",
        bodyText: `I am SmartSage - your digital sherpa. I am here to help you with anything on IA smart Platform`,
      },
      {
        timeStamp: getCurrentDateTimeString(),
        userType: "bot",
        userName: "Smart Sage - The smart bot assist",
        headerTitle: "Please select what you want my help with!",
        bodyType: "chips",
        bodyText: [
          {
            displayText: "Insights",
            flow_type: "insights",
            interactable: true,
            actionType: "direct",
            actionName: "setUserFlow",
          },
          {
            displayText: "General Navigation",
            flow_type: "navigation",
            interactable: true,
            actionType: "direct",
            actionName: "setUserFlow",
          },
        ],
      },
    ];
    chatDataRef.current = initialGreet;
  };

  const setUserFlow = async (data) => {
    setLoader(true);
    const currentTimeString = getCurrentDateTimeString();
    let headerTitle;
    let screenOptions = {};
    const userChat = {
      timeStamp: currentTimeString,
      userType: "user",
      bodyText: data.displayText,
      flow_type: data.flow_type,
    };
    const loaderData = {
      userName: "Smart Sage",
      timeStamp: currentTimeString,
      userType: "loader",
    };
    chatDataRef.current = [...chatDataRef.current, userChat, loaderData];
    try {
      const options = await fetchScreensForModule(data.flow_type);
      if (options.data.screen_name?.length) {
        headerTitle = `Please select for which Modules you ${
          data.flow_type === "navigation"
            ? "need help for."
            : "want insights for."
        }`;
        screenOptions = setScreens(
          options.data.screen_name,
          headerTitle,
          currentTimeString,
          data.flow_type
        );
        chatDataRef.current = [
          ...chatDataRef.current?.filter((data) => data.userType != "loader"),
          screenOptions,
        ];
      } else {
        const failResponseText = {
          timeStamp: getCurrentDateTimeString(),
          userType: "bot",
          userName: "Smart Sage - The smart bot assist",
          bodyText: options.data.message,
          headerTitle: "",
          noShowHeaderTitle: true,
          bodyType: "text",
        };
        chatDataRef.current = [
          ...chatDataRef.current?.filter((data) => data.userType != "loader"),
          failResponseText,
        ];
      }
      setFlowType(data.flow_type);
      setLoader(false);
    } catch (error) {
      const failResponseText = {
        timeStamp: getCurrentDateTimeString(),
        userType: "bot",
        userName: "Smart Sage - The smart bot assist",
        bodyText: "Failed to fetch user screens. Please try again later.",
        headerTitle: "",
        noShowHeaderTitle: true,
        bodyType: "text",
      };
      chatDataRef.current = [
        ...chatDataRef.current?.filter((data) => data.userType != "loader"),
        failResponseText,
      ];
      setLoader(false);
      console.error("error", error);
    }
  };

  const setScreens = (options = [], headerTitle, timeString, flow_type) => {
    return {
      timeStamp: timeString,
      userType: "bot",
      userName: "Smart Sage - The smart bot assist",
      headerTitle: headerTitle,
      bodyType: "chips",
      bodyText: options.map((option) => {
        return {
          ...option,
          displayText: option.screen_name,
          flow_type: flow_type,
          interactable: true,
          actionType: "direct",
          actionName: "setUserScreenAndFlow",
        };
      }),
    };
  };

  const setUserScreenAndFlow = (data) => {
    console.log("changes setUserScreenAndFlow ->", data);
    setFlowType(data?.flow_type);
    setScreenName(data?.screen_name);
    localStorage.setItem("smartBotScreenName", data?.screen_name);
    setQuestionIndex(data?.question_index);
    if (data?.link) {
      setCurrentAppLink(data?.link);
      localStorage.setItem("smartBotCurrentAppLink", data?.link);
    } else {
      let link;
      const typeOptions =
        data.flowType === "navigation"
          ? [...navigationOptions]
          : [...insightsOptions];
      typeOptions.forEach((option) => {
        if (option.screen_name == data?.screen_name) {
          link = option.link;
        }
      });
      if (link) {
        setCurrentAppLink(link);
        localStorage.setItem("smartBotCurrentAppLink", link);
      } else {
        setCurrentAppLink(null);
        localStorage.removeItem("smartBotCurrentAppLink");
      }
    }
    if (data.actionType === "indirect") {
      setUserInput(data.displayText);
    } else {
      fetchUserResultsFromQuery(data, true);
    }
  };

  const getCurrentDateTimeString = () => {
    const currentDateTime = moment();
    return currentDateTime.format(dateFormat);
  };

  const fetchUserResultsFromQuery = async (
    refObject,
    fetchQuestions = false
  ) => {
    setLoader(true);
    const currentTimeString = getCurrentDateTimeString();
    const input = fetchQuestions ? refObject.screen_name : userInput;
    setUserInput("");
    const userChat = {
      timeStamp: currentTimeString,
      userType: "user",
      bodyText: input,
      screen_name: fetchQuestions ? refObject.screen_name : screenName,
      flow_type: fetchQuestions ? refObject.flow_type : flowType,
      hasDirectActionType: fetchQuestions,
    };
    const loaderData = {
      userName: "Smart Sage - The smart bot assist",
      timeStamp: currentTimeString,
      userType: "loader",
    };
    chatDataRef.current = [...chatDataRef.current, userChat, loaderData];
    try {
      let queryResponse;
      let getUpdatedChat;
      let inCaseOfNoDataObject = {
        response_heading: "",
        response:
          "Sorry, but I can't help you with that. It's out of the scope of my knowledge base.",
      };
      if (fetchQuestions) {
        queryResponse = await fetchRelatedQuestions(
          refObject.flow_type,
          refObject.screen_name
        );
        if (isNull(queryResponse?.data)) {
          getUpdatedChat = parseResponse(inCaseOfNoDataObject, "text");
        } else {
          getUpdatedChat = parseResponse(
            queryResponse.data.questions[0],
            "questions"
          );
        }
      } else {
        let body = {};
        if (flowType === "insights") {
          //flowType
          body = {
            question_index: questionIndex,
            screen_name: screenName,
            question: input,
          };
        } else {
          body = {
            query: input,
          };
        }
        // when insights completes send flowtype
        queryResponse = await resolveChatQuery(body); //flowType
        if (isNull(queryResponse?.data)) {
          getUpdatedChat = parseResponse(inCaseOfNoDataObject, "text");
        } else {
          getUpdatedChat = parseResponse(queryResponse.data, "text");
        }
      }
      chatDataRef.current = [
        ...chatDataRef.current?.filter((data) => data.userType != "loader"),
        getUpdatedChat,
      ];
      console.log("test2", chatDataRef);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("error", error);
    }
  };

  const parseResponse = (data, type) => {
    const timeString = getCurrentDateTimeString();
    switch (type) {
      case "text":
        return {
          timeStamp: timeString,
          userType: "bot",
          userName: "Smart Sage",
          bodyText: data.response,
          headerTitle: data.response_heading,
          noShowHeaderTitle: true,
          bodyType: "text",
        };
      case "questions":
        return {
          timeStamp: timeString,
          userType: "bot",
          userName: "Smart Sage",
          headerTitle:
            "Here are few questions that users are typically interested in.",
          bodyType: "questions",
          bodyText: data.map((quesObj) => {
            //data[type]
            return {
              ...quesObj,
              displayText: quesObj.question,
              interactable: true,
              actionType: "indirect",
              actionName: "setUserScreenAndFlow",
            };
          }),
        };
    }
  };

  const navigateToTheScreen = () => {
    try {
      navigate(currentAppLink);
    } catch (error) {
      console.error("navigateToTheScreen error", error);
    }
  };

  const displaySnackMessages = (message, variant) => {
    try {
      setSnackMessage({
        message,
        variant,
      });
      setDisplayMessage(true);
      // dispatch(
      //   addSnack({
      //     message: message,
      //     options: {
      //       variant: variant,
      //     },
      //   })
      // );
      //alert("snack should be called here");
    } catch (error) {
      console.error("displaySnackMessages error", error);
    }
  };

  const refreshAndUpdateUserManual = async () => {
    try {
      setRefreshLoader(true);
      let body = {
        document_id: "1n6Sh07Tqhq5R8OI5Smm3KDHka21-VwMxtD0DBT7GB1o",
      };
      let response = await refreshAndUpdateUserManualApi(body);
      if (response?.data?.data?.status) {
        displaySnackMessages("Refresh Successful", "success");
      } else {
        displaySnackMessages("Refresh Failed", "error");
      }
      setRefreshLoader(false);
    } catch (error) {
      displaySnackMessages("Refresh Failed", "error");
      console.error("refreshAndUpdateUserManual error", error);
      setRefreshLoader(false);
    }
  };

  return (
    <>
      {!minimizedMode ? (
        <>
          {showModal && (
            <div className="fixed-bot-panel">
              <div className="smart-bot-modal-header">
                <div className="flex-row layout-align-space-between">
                  <Typography className="bot-title" variant="h6">
                    Smart Sage
                  </Typography>
                  <div className="flex-row layout-align-center gap">
                    <StyledRefreshIcon
                      className="cursor-pointer"
                      onClick={
                        refreshLoader
                          ? () => {}
                          : () => refreshAndUpdateUserManual()
                      }
                      loading={refreshLoader.toString()}
                    />
                    <RemoveIcon
                      className="cursor-pointer svg-style"
                      onClick={() => setMinimizedMode(true)}
                    />
                    <CloseIcon
                      className="cursor-pointer svg-style"
                      onClick={() => {
                        setShowModal(false);
                        setMinimizedMode(false);
                        props.closeBot(false);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div
                ref={chatBodyRef}
                className="smart-bot-modal-body flex-row flex-column"
              >
                {chatDataRef.current ? (
                  <MessageTemplate
                    templateData={chatDataRef.current}
                    clearChatSession={clearChatSession}
                    endCurrentSession={endCurrentSession}
                    setUserFlow={setUserFlow}
                    setUserScreenAndFlow={setUserScreenAndFlow}
                    navigateToTheScreen={navigateToTheScreen}
                    screenName={screenName}
                    currentAppLink={currentAppLink}
                  />
                ) : (
                  "no data"
                )}
              </div>
              <div className="smart-bot-modal-footer">
                {chatDataRef.current?.length > 3 ? (
                  <Typography className="foot-note" variant="" component="span">
                    **I can make mistakes. Please re-check critical
                    information/steps. Though I am constantly learning and
                    getting better
                  </Typography>
                ) : (
                  <></>
                )}
                <form
                  className="flex-row layout-align-center gap form-container"
                  onSubmit={(event) => {
                    event.preventDefault();
                    fetchUserResultsFromQuery();
                  }}
                  disabled={loader || !Boolean(flowType)}
                >
                  <Input
                    className="smart-bot-user-input"
                    value={userInput}
                    onChange={(event) => {
                      setUserInput(event.target.value);
                    }}
                    placeholder="Type your question here.."
                    disabled={loader || !Boolean(flowType)}
                  />
                  <button
                    className="smart-bot-send-button"
                    disabled={
                      !userInput?.length || loader || !Boolean(flowType)
                    }
                    onClick={fetchUserResultsFromQuery}
                  >
                    <img src={SendIcon} />
                  </button>
                </form>
              </div>
            </div>
          )}
        </>
      ) : (
        <MinimizedContainer
          setShowModal={setShowModal}
          setMinimizedMode={setMinimizedMode}
        />
      )}
      {displayMessage ? (
        <Snackbar
          message={snackMessage.message}
          alertType={snackMessage.variant}
        />
      ) : null}
    </>
  );
};

const mapStateToProps = (store) => {
  return {
    smartBotActive: store.smartBot.smartBotActive,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //setSmartBotActive: () => dispatch(setSmartBotActive()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SmartBot);
