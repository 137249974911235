import React, { useEffect, useState, useRef, useMemo } from "react";
import CloseRoundIcon from "../../../assets/closeRoundIcon.svg";
import BotFace from "../../../assets/botFace.svg";
import { Typography } from "@mui/material";
import { pxToRem } from "./utils";

export default function MinimizedContainer(props) {
  const { setShowModal, setMinimizedMode } = props;
  const [position, setPosition] = useState(null);
  const grabPositionRef = useRef(null);
  const isDraggingRef = useRef(null);
  const minimizedBtnRef = useRef();

  const handleMouseDown = (e) => {
    grabPositionRef.current = {
      x: e.clientX - minimizedBtnRef.current.offsetLeft,
      y: e.clientY - minimizedBtnRef.current.offsetTop,
    };
    setPosition({
      x: minimizedBtnRef.current.offsetLeft,
      y: minimizedBtnRef.current.offsetTop,
    });
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  const handleMouseMove = (e) => {
    if (grabPositionRef.current) {
      isDraggingRef.current = true;
      setPosition({
        x: e.clientX - grabPositionRef.current.x,
        y: e.clientY - grabPositionRef.current.y,
      });
    }
  };

  const handleMouseUp = () => {
    grabPositionRef.current = null;
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
  };

  return (
    <div
      className="minimized-container"
      onMouseDown={handleMouseDown}
      ref={minimizedBtnRef}
      style={{
        left: position?.x
          ? pxToRem(position?.x)
          : `calc(100% - 2.5rem - ${
              minimizedBtnRef?.current?.offsetWidth || 230
            }px)`, //`calc(100% - 2.5rem - ${props.minimizedBtnRef?.current?.offsetWidth || 0}px)`
        top: position?.y
          ? pxToRem(position?.y)
          : `calc(100vh - 2.5rem - ${
              minimizedBtnRef?.current?.offsetHeight || 70
            }px)`,
      }}
    >
      <button
        className="close-minimize-btn padding_0"
        onClick={() => {
          if (!isDraggingRef.current) {
            setShowModal(false);
            setMinimizedMode(false);
            props.closeBot(false);
          } else {
            isDraggingRef.current = null;
          }
        }}
      >
        {/* <CloseRoundIcon /> */}
        <img src={CloseRoundIcon} />
      </button>
      <button
        className="minimized-button"
        onClick={() => {
          if (!isDraggingRef.current) {
            setShowModal(true);
            setMinimizedMode(false);
          } else {
            isDraggingRef.current = null;
          }
        }}
      >
        <img src={BotFace} />
        {/* <BotFace /> */}
        <Typography
          component="span"
          variant="h5"
          className="minimized-button-text"
        >
          Questions? I’m here to help
        </Typography>
      </button>
    </div>
  );
}
