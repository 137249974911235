export const navigationOptions = [
  {
    link: "/decision-dashboard",
    screen_name: "Dashboard",
    flow_type: "navigation",
  },
  {
    link: `/create-allocation/?step=0`,
    flow_type: "navigation",
    screen_name: "Allocation",
  },
  {
    link: "/store-eligibility-grouping",
    flow_type: "navigation",
    screen_name: "Configurations",
  },
  {
    link: "/configuration",
    flow_type: "navigation",
    screen_name: "Grouping",
  },
];

export const insightsOptions = [
  {
    link: "/inventory-smart/decision-dashboard",
    screen_name: "Dashboard",
    flow_type: "insights",
  },
  {
    link: `/inventory-smart/create-allocation/?step=0`,
    flow_type: "insights",
    screen_name: "Allocation",
  },
  {
    link: "/inventory-smart/store-eligibility-grouping",
    flow_type: "insights",
    screen_name: "Configurations",
  },
  {
    link: "/inventory-smart/configuration",
    flow_type: "insights",
    screen_name: "Grouping",
  },
  {
    link: "/inventory-smart/constraints",
    flow_type: "insights",
    screen_name: "Constraints",
  },
  {
    link: "/inventory-smart/ada/dashboard",
    flow_type: "insights",
    screen_name: "ADA Visual",
  },
];
